import "@popperjs/core/lib/popper";

window.bootstrap = require("bootstrap");

import "./js/breadcrumbs";
import "./js/table";
import "./js/footer";

import "./scss/style.scss";

let pageTitle = decodeURI(
  window.location.pathname.substring(
    1,
    window.location.pathname.lastIndexOf("/")
  )
);
if (pageTitle === "/") pageTitle = "Index";

document.title = pageTitle;

let tooltipTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="tooltip"]')
);
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl);
});

window.addEventListener("load", (event) => {
  document.body.style.visibility = "visible";
});
