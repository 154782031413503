/**
 * Gets file type.
 *
 * @return string the type of file
 * @param filename
 */
function getFileType(filename) {
  if (filename.endsWith("/")) {
    return "folder";
  } else {
    return "file";
  }
}

/**
 * Gets icon.
 *
 * @return string icon html string
 * @param filetype
 */
function getIcon(filetype) {
  if (filetype === "folder") {
    return "<i class='fa-solid fa-folder'></i>";
  } else if (filetype === "file") {
    return "<i class='fa-solid fa-file'></i>";
  }
}

let tableWrapper = document.createElement("div");
let table = document.querySelector("#list");
let tableHeader = document.querySelector("#list>thead");
let parent = table.parentNode;

parent.replaceChild(tableWrapper, table);
tableWrapper.appendChild(table);
tableWrapper.classList.add("table-responsive", "container");

table.classList.add("table", "table-striped", "table-hover");
tableHeader.classList.add("sticky-top");

if (window.location.pathname !== "/") {
  list.deleteRow(1);
}

function copyUrl(event) {
  let copyBtn = event.currentTarget.icon;
  let tooltip = bootstrap.Tooltip.getInstance(copyBtn);

  event.preventDefault();
  navigator.clipboard.writeText(event.currentTarget.link.href);
  copyBtn.setAttribute("data-bs-original-title", "Copied!");
  tooltip.show();
  copyBtn.setAttribute("data-bs-original-title", "Copy to clipboard!");
}

function copyPlaylist() {
  let copy = "";

  for (let i = 0; i < table.rows.length; i++) {
    if (i === 0)
      continue;

    let a = table.rows[i].cells[1].parentElement.getElementsByTagName("a")[0];
    copy += a.origin + a.pathname;

    if (i !== table.rows.length - 1)
      copy += ",";
  }

  navigator.clipboard.writeText(copy);
}

let cell = table.rows[0].insertCell(table.rows[0].cells.length);
cell.innerHTML = "<a href='#'>Copy<a/>";
cell.addEventListener ("click", copyPlaylist, false);

for (let i = 0; i < table.rows.length; i++) {
  filetype = getFileType(table.rows[i].cells[0].children[0].innerHTML);
  table.rows[i].insertCell(0).outerHTML =
    '<th scope="row">' + (i > 0 ? getIcon(filetype) : "") + "</th>";

  if (i > 0) {
    table.rows[i].insertCell(4).innerHTML =
      ' <a href="#" data-bs-toggle="tooltip" data-bs-placement="right" title="Copy to clipboard"><i class="fa-solid fa-clipboard"></i></a>';

    let copyBtn = table.rows[i].cells[4].children[0];

    copyBtn.addEventListener("click", copyUrl);
    copyBtn.link = table.rows[i].cells[1].children[0];
    copyBtn.icon = table.rows[i].cells[4].children[0];

    let name = table.rows[i].cells[1].children[0].innerHTML;

    if (filetype === "folder")
      table.rows[i].cells[1].children[0].innerHTML = name.substring(
        0,
        name.length - 1
      );
  }
}

for (let i = 0; i < table.rows[0].cells.length; i++) {
  table.rows[0].cells[i].style = null;
  table.rows[0].cells[i].setAttribute("scope", "col");
}

for (let i = 1; i < table.rows[0].cells.length - 1; i++) {
  if (table.rows[0].cells[i].children[1].href === window.location.href) {
    table.rows[0].cells[i].children[1].innerHTML = "&nbsp;&#8593;&nbsp;";
  }
}
