/**
 * Prints a single breadcrumb.
 *
 * @return string html string
 * @param name
 * @param url
 * @param active
 */
function createBreadcrumb(name, url, active) {
  return (
    '<li class="breadcrumb-item' +
    (active ? ' active" aria-current="page"' : "") +
    '">' +
    (active ? "" : '<a href="' + url + '">') +
    name +
    (active ? "" : "</a>")
  );
}

let folders = window.location.pathname.replace(/\/$/, "").split("/");
let nav = "";
let path = "";

for (let i = 0; i < folders.length; i++) {
  path += folders[i] + "/";
  nav += createBreadcrumb(
    i === 0 ? "Index" : decodeURIComponent(folders[i]),
    path,
    i === folders.length - 1
  );
}

document.querySelector("#breadcrumb").innerHTML = nav;
